.header-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: cadetblue;
  font-size: x-large;
}

.stepper-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 20px auto;
  padding: 10px;
  height: 10rem;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.line {
  width: 20px; /* Adjust the width as needed */
  height: 2px; /* Adjust the height as needed */
  background-color: #ccc; /* Change the color as desired */
  margin: 0 auto; /* Center the line */
}

.circle {
  width: 30px;
  height: 30px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
}

.step.active .circle {
  background-color: #007bff;
}

.label {
  margin-top: 5px;
  font-size: 14px;
  color: #888;
}

.step.active .label {
  color: #333;
}

.container01 {
  display: flex;
  justify-content: space-evenly;
  max-width: 100%;
}

.cards-container {
  display: flex;
  gap: 30rem
}

.card {
  max-width: 100%;
  width: 16rem;
  border: 3px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
